import BaseButton from '@components/basebutton';
export const bootstrapCoursematrix = () => {
    const coursematrixElem = document.querySelector('.coursematrix');
    if (coursematrixElem) {
        const toggleDetailsElems = coursematrixElem.querySelectorAll('[data-action="toggle-details"]');
        const toggleProgressElems = coursematrixElem.querySelectorAll('[data-action="toggle-progress"]');
        if (toggleDetailsElems) {
            const toggleDetailsClassName = 'block--details-locked';
            Array.prototype.forEach.call(toggleDetailsElems, (elem) => {
                const categoryBlockElem = elem.closest('.category-block');
                if (categoryBlockElem) {
                    const actionName = `matrix-${categoryBlockElem.dataset.type}-toggle-details`;
                    const toggleDetailsButton = new BaseButton(elem, actionName, {
                        defaultStatus: 'released',
                        title: 'Details anzeigen ein/aus',
                        actionPressed: function (e) {
                            categoryBlockElem.classList.add(toggleDetailsClassName);
                        },
                        actionReleased: function (e) {
                            categoryBlockElem.classList.remove(toggleDetailsClassName);
                        }
                    });
                }
            });
        }
        if (toggleProgressElems) {
            const toggleProgressClassName = 'block--progress-locked';
            Array.prototype.forEach.call(toggleProgressElems, (elem) => {
                const categoryBlockElem = elem.closest('.category-block');
                if (categoryBlockElem) {
                    const actionName = `matrix-${categoryBlockElem.dataset.type}-toggle-progress`;
                    const toggleProgressButton = new BaseButton(elem, actionName, {
                        defaultStatus: 'released',
                        title: 'Fortschritt der Lerneinheiten anzeigen ein/aus',
                        actionPressed: function (e) {
                            categoryBlockElem.classList.add(toggleProgressClassName);
                        },
                        actionReleased: function (e) {
                            categoryBlockElem.classList.remove(toggleProgressClassName);
                        }
                    });
                }
            });
        }
    }
};
